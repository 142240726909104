<template>
    <div :class="$route.meta.title === '客服任务详情页' ? 'task-kefu-container' : 'task-container'">
        <!-- 运营推广 -->
        <div class="task-main" v-if="$route.name === 'operationPromotionTaskDetail'">
            <template v-if="operation_introduction">
                <div class="task-box">
                    <div class="title fz-24">——情景创设——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="dashed-box">
                            <template v-if="operation_introduction.scene_creation">
                                <span v-html="operation_introduction.scene_creation"></span>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="task-box">
                    <div class="title fz-24">——产品资料——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="table-control thrid-table">
                            <table class="table-control">
                                <tbody>
                                    <tr>
                                        <td>序号</td>
                                        <td>商品图片</td>
                                        <td>商品名称</td>
                                        <td>商品介绍</td>
                                    </tr>
                                    <template v-for="item, index in operation_introduction.goods_data">
                                        <tr>
                                            <td>{{ index + 1 }}</td>
                                            <td>
                                                <img style="width: 78px;height: 78px;border-radius: 4px;"
                                                    :src="item.good_img" />
                                            </td>
                                            <td>{{ item.good_title }}</td>
                                            <td>{{ item.description }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <template v-if="selection_introduction">
                    <div class="task-box">
                        <div class="title fz-24">——背景资料——</div>
                        <div class="tag"></div>
                        <div class="context-box">
                            <div class="dashed-box">
                                <template v-if="selection_introduction.task_background">
                                    <span v-html="selection_introduction.task_background"></span>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="task-box">
                        <div class="title fz-24">——任务要求——</div>
                        <div class="tag"></div>
                        <div class="context-box">
                            <div class="dashed-box"><span v-html="selection_introduction.task_requirements"></span></div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
        <!-- 推荐引擎推广 -->
        <div class="task-main" v-if="$route.name === 'operationPromotionShopTaskDetail'">
            <div class="task-box">
                <div class="title fz-24">——情景创设——</div>
                <div class="tag"></div>
                <div class="context-box">
                    <div class="dashed-box">
                        <template v-if="operation_introduction">
                            <span v-html="operation_introduction.scene_creation"></span>
                        </template>
                    </div>
                </div>
            </div>
            <template v-if="booth_introduction">
                <div class="task-box">
                    <div class="title fz-24">——产品资料——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="table-control thrid-table">
                            <table class="table-control">
                                <tbody>
                                    <tr>
                                        <td>序号</td>
                                        <td>商品图片</td>
                                        <td>商品名称</td>
                                        <td>商品介绍</td>
                                    </tr>
                                    <template v-for="item, index in operation_introduction.goods_data">
                                        <tr>
                                            <td>{{ index + 1 }}</td>
                                            <td>
                                                <img style="width: 78px;height: 78px;border-radius: 4px;"
                                                    :src="item.good_img" />
                                            </td>
                                            <td>{{ item.good_title }}</td>
                                            <td>{{ item.description }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="task-box">
                    <div class="title fz-24">——背景资料——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="dashed-box">
                            <template v-if="booth_introduction.task_background">
                                <span v-html="booth_introduction.task_background"></span>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="task-box">
                    <div class="title fz-24">——任务要求——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="dashed-box"><span v-html="booth_introduction.tack_demands"></span></div>
                    </div>
                </div>
            </template>
        </div>
        <!-- 搜索引擎推广 -->
        <div class="task-main" v-if="$route.name === 'operationPromotionsearchTaskDetail'">
            <div class="task-box">
                <div class="title fz-24">——情景创设——</div>
                <div class="tag"></div>
                <div class="context-box">
                    <div class="dashed-box">
                        <template v-if="operation_introduction">
                            <span v-html="operation_introduction.scene_creation"></span>
                        </template>
                    </div>
                </div>
            </div>
            <template v-if="search_adv_introduction">
                <div class="task-box">
                    <div class="title fz-24">——产品资料——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="table-control thrid-table">
                            <table class="table-control">
                                <tbody>
                                    <tr>
                                        <td>序号</td>
                                        <td>商品图片</td>
                                        <td>商品名称</td>
                                        <td>商品介绍</td>
                                    </tr>
                                    <template v-for="item, index in operation_introduction.goods_data">
                                        <tr>
                                            <td>{{ index + 1 }}</td>
                                            <td>
                                                <img style="width: 78px;height: 78px;border-radius: 4px;"
                                                    :src="item.good_img" />
                                            </td>
                                            <td>{{ item.good_title }}</td>
                                            <td>{{ item.description }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="task-box">
                    <div class="title fz-24">——背景资料——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="dashed-box">
                            <template v-if="search_adv_introduction.background != undefined">
                                <span v-html="search_adv_introduction.background"></span>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="task-box">
                    <div class="title fz-24">——任务要求——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="dashed-box">
                            <template v-if="search_adv_introduction.requirement != undefined">
                                <span v-html="search_adv_introduction.requirement"></span>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <!-- 客服 -->
        <div class="task-main" v-if="$route.name === 'shopCustomerServiceTaskDetail'">
            <template v-if="kefu_introduction">
                <div class="task-box">
                    <div class="title" style="font-size:20px;">——智能客服问答处理任务背景——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="dashed-box">
                            <template v-if="kefu_introduction.task_background != ''">
                                <span v-html="kefu_introduction.task_background"></span>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="task-box">
                    <div class="title" style="font-size:20px;">——智能客服问答处理任务要求——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="dashed-box">
                            <template v-if="kefu_introduction.tack_demands">
                                <span v-html="kefu_introduction.tack_demands"></span>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="task-box">
                    <div class="title" style="font-size:20px;">——客户异议处理任务背景——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="dashed-box">
                            <template v-if="kefu_introduction.exception_task_background">
                                <span v-html="kefu_introduction.exception_task_background"></span>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="task-box"> 
                    <div class="title" style="font-size:20px;">——客户异议处理任务要求——</div>
                    <div class="tag"></div>
                    <div class="context-box">
                        <div class="dashed-box">
                            <template v-if="kefu_introduction.exception_tack_demands">
                                <span v-html="kefu_introduction.exception_tack_demands"></span>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { getTaskTotalDetail } from "@/utils/apis";
export default {
    data() {
        return {
            operation_introduction: void 0, //运营模板数据
            search_adv_introduction: void 0, //搜索广告数据
            booth_introduction: void 0, //展位广告数据
            kefu_introduction: void 0, //客服数据
        }
    },
    methods: {
        getTask() {
            getTaskTotalDetail().then((res) => {
                if (res.code === 200) {
                    this.operation_introduction = res.data.operation_introduction
                    this.search_adv_introduction = res.data.search_adv_introduction
                    this.booth_introduction = res.data.booth_introduction
                    this.kefu_introduction = res.data.kefu_introduction
                    this.selection_introduction = res.data.selection_introduction
                }
            })
        }
    },
    mounted() {
        this.getTask()
    }
}

</script>

<style lang="scss" scoped>
::v-deep .table-control {
    position: relative;
    z-index: 5;
    width: 100%;

    &>p {
        position: relative;
        z-index: 5;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
    }

    table {
        margin-top: 5px;
        border: 2px dashed #AAC7CF;
        border-radius: 20px;
        border-collapse: collapse;
        border-style: hidden;
        box-shadow: 0 0 0 2px #AAC7CF;
        overflow: hidden;
        width: 100%;
    }

    td,
    th {
        text-align: center;
        border: 1px dashed #AAC7CF;
        background-color: #ffffff;
        border-collapse: separate;
        height: 60px;
        font-size: 18px;
    }

    table td {
        height: 80px;
        padding: 10px 30px;
        background-color: #ffffff;

        p {
            margin: 0;
        }
    }

    &.thrid-table {
        table {
            tr {
                &:nth-child(even) {
                    td {
                        background: #ccdafc;
                    }
                }

                &:nth-child(odd) {
                    td {
                        background: #ffffff;
                    }
                }

                th {
                    background: #ffffff;
                }
            }
        }
    }

    &.fourth-table {
        table {
            tr {
                &:nth-child(odd) {
                    td {
                        background: #ffceb6;
                    }
                }

                &:nth-child(even) {
                    td {
                        background: #ffffff;
                    }
                }

                th {
                    background: #ffffff;
                }
            }
        }
    }
}

.task-kefu-container {
    margin: 0 auto;
    width: 80%;
    height: 100%;
    min-height: 950px;
    background: url('../../../../assets/images/taskdetail.png') no-repeat;
    box-sizing: border-box;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFB974;
    padding-bottom: 30px;
}

.task-container {
    width: 100%;
    height: 100%;
    min-height: 950px;
    background: url('../../../../assets/images/taskdetail.png') no-repeat;
    box-sizing: border-box;
    padding: 0 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFB974;
    padding-bottom: 30px;
}

.task-main {
    .task-box {
        height: auto;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        margin-top: 40px;

        .tag {
            height: 79px;
            width: 79px;
            position: absolute;
            left: -3%;
            top: 5.5%;
            background: url('../../../../assets/images/task_tag.png') no-repeat;
            z-index: 9;
        }

        .title {
            width: 397px;
            min-height: 60px;
            background: url('../../../../assets/images/taskrectangle.png') no-repeat;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
        }
        .fz-24{
            font-size: 24px;
        }

        .context-box {
            width: 100%;
            height: 100%;
            min-height: 150px;
            background: linear-gradient(180deg, #FFFFFF 0%, #FFFDEB 100%);
            box-shadow: 0px 10px 30px 0px rgba(247, 162, 162, 0.62);
            border-radius: 20px;
            padding: 20px;

            .dashed-box {
                border-radius: 8px;
                width: 100%;
                height: 100%;
                min-height: 150px;
                border: 1px dashed #AAC7CF;

                span {
                    padding: 20px;
                    display: block;
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 20px;
                    color: #DC3D21;
                }
            }

            .thrid-table {

                td,
                th {
                    text-align: center;
                    border: 1px dashed #AAC7CF;
                    background-color: #ffffff;
                    border-collapse: separate;
                    height: 60px;
                    font-size: 18px;
                }

                table td {
                    height: 80px;
                    padding: 10px 30px;
                    background-color: #ffffff;

                    p {
                        margin: 0;
                    }
                }

                table {
                    tr {
                        &:nth-child(even) {
                            td {
                                background: #ccdafc;
                            }
                        }

                        &:nth-child(odd) {
                            td {
                                background: #ffffff;
                            }
                        }

                        th {
                            background: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
</style>